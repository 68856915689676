import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import CarrouselHome from '../components/CarrouselHome';
import CarrouselServices from '../components/CarrouselServices';
import Footer from '../components/Footer';
import ModalDetailStore from '../components/ModalDetailStore';
import content1Img from '@img/content-1-img.webp';
import content2Img from '@img/content-2-img.webp';
import content2_1Img from '@img/content-2-1-img.webp';
import petshopService from '@img/c_pet-store.webp';
import entrenamientoService from '@img/c_dog-training.webp';
import crematorioService from '@img/c_pet-crematorium.webp';
import hospedajeService from '@img/c_pet-boarding.webp';
import veterinariasService from '@img/c_veterinarian.webp';
import groomerServices from '@img/c_dog-grooming.webp';
import cafeService from '@img/c_dog-friendly-cafe.webp';
import experienciaService from '@img/c_experiences.webp';
import refugioService from '@img/c_pet-shelter.webp';
import masService from '@img/mas-service.webp';
import { tracking } from '../api';
import { useNavigate, useLocation } from "react-router-dom";
import useCategoryList from '../hooks/useCategoryList';
import useStoresRandomByCategoryList from '../hooks/useStoresRandomByCategoryList';
import Loading from '../components/Loading';
import { ListUniqueStores } from '../util/anyFunctions';
import Card from '../components/Card';
import firuboxWeb from '@img/firubox_web.webp';
import firuboxMobile from '@img/firubox_mobile.webp';

import '@styles/home.scss';

const Home = () => {
    const location = useLocation();
    const [categories, setCategories] = useState([]);
    const [services, setServices] = useState([]);
    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [categoryValue, setCategoryValue] = useState(null);
    const [storefavorite, setstoreFavorite] = useState(null);
    const [dataStore, setDataStore] = useState(null);
    const [loading2, setLoading2] = useState(false);
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const { categories } = await useCategoryList('available');
            const services =  categories.filter(category => category.value !== 'available')
            setServices(services);
            const { stores } = await useStoresRandomByCategoryList();
            setStores(ListUniqueStores(stores));
            setCategories(categories);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching categories:", error);
            setLoading(false);
        }
    };

    const fetchTrackingData = async () => {
        try {
            const loggedInUser = localStorage.getItem("_flat_data");
            if(!loggedInUser){
                await tracking({ page: 'home' })
            }
        } catch (error) {
            console.error('Error al obtener los datos:', error);
        }
    };
    useEffect(() => {
        fetchTrackingData();
        fetchData();
    }, []);

    useEffect(() => {
        window.dataLayer.push({
            event: "page_view"
        })
    }, [location]);

    const handleFirubox = () =>{
        navigate("/dashboard/firubox")
    }

	return (
        <>
            {
                loading ? (<Loading/>):
                <div className="home">
                    <header className="header"><Header/></header>
                    <CarrouselHome categories={categories}/>
                    <CarrouselServices services={services}/>
                    <div className='Content'>
                        <div className='firubox-services'>
                                <div className="firubox-image">
                                    <picture>
                                        <source media="(max-width: 992px)" srcSet={firuboxMobile} />
                                        <source media="(min-width: 993px)" srcSet={firuboxWeb} />
                                        <img src={firuboxWeb} alt="firubox" />
                                    </picture>
                                </div>
                                <div className='firulife-buttom' onClick={handleFirubox}>
                                    Obtener FiruBox
                                </div>
                        </div>
                        <div className='content-services'>
                            <div className='title'>¿Qué puedes encontrar?</div>
                            <div className='content-cards'>
                            {
                                stores.map((item, index) => (
                                    <Card
                                            name={item.name}
                                            id={item.id}
                                            lat={item.lat}
                                            lng={item.lng}
                                            type={item.type}
                                            category={item.categoryName}
                                            categoryValue={item.categoryValue}
                                            setCategoryValue={setCategoryValue}
                                            reference={item.reference}
                                            image={item.image}
                                            img_src={item.iconlist}
                                            storeId={item.id}
                                            setDataStore={setDataStore}
                                            isFavorite={item.isFavorite}
                                            setstoreFavorite={setstoreFavorite}
                                            setLoading2={setLoading2}
                                            key={index}
                                        />
                                ))
                            }
                            </div>

                        </div>
                        <div className='content-1'>
                            <div className='coontent-1-left'>
                                <img src={content1Img} alt="firulife-img" />
                            </div>
                            <div className='coontent-1-right'>
                                <div className='title'>
                                    ¿Qué es FiruLife?
                                </div>
                                <div className='subtitle'>
                                    El paraíso para los amantes de las mascotas
                                </div>
                                <div className='list'>
                                    <ol>
                                        <li>FiruLife ofrece soluciones para el entretenimiento y cuidado de mascotas.</li>
                                        <li>Su mapa interactivo presenta áreas de juegos y servicios especializados.</li>
                                        <li>Desde parques hasta veterinarias, ofrece todo para una vida feliz y saludable.</li>
                                        <li>Comprometidos con la calidad, garantizamos servicios cercanos y confiables.</li>
                                        <li>Únete a FiruLife para una experiencia emocionante y memorable para tu mascota.</li>
                                    </ol>
                                </div>
                                <div className='button-search' onClick={() => navigate("/search")}>
                                    Buscar
                                </div>
                            </div>
                        </div>
                        <div className='content-2'>
                            <div className='coontent-2-left'>
                                <div className='title'>
                                    Sé nuestro aliado
                                </div>
                                <div className='text'>
                                Somos tu mejor aliado para publicitar tu servicio, ingresa e inscribe tu servicio totalmente gratis.
                                </div>
                                <div className='services'>
                                    <div className='service-'>
                                        <div className='content-service'><div className="category-image"><img src={petshopService} alt="firulife-img" /></div><div className="category-name">Petshops</div></div>
                                        <div className='content-service'><div className="category-image"><img src={veterinariasService} alt="firulife-img" /></div> <div className="category-name">Veterinarias</div></div>
                                        <div className='content-service'><div className="category-image"><img src={entrenamientoService} alt="firulife-img" /></div> <div className="category-name">Entrenamiento canino</div></div>
                                        <div className='content-service'><div className="category-image"><img src={crematorioService} alt="firulife-img" /></div> <div className="category-name">Crematorios</div></div>
                                        <div className='content-service'><div className="category-image"><img src={hospedajeService} alt="firulife-img" /></div> <div className="category-name">Hospedaje para mascotas</div></div>
                                    </div>
                                    <div className='service-'>
                                        <div className='content-service'><div className="category-image"><img src={cafeService} alt="firulife-img" /></div> <div className="category-name">Cafeterías Pet friendly</div></div>
                                        <div className='content-service'><div className="category-image"><img src={groomerServices} alt="firulife-img" /></div> <div className="category-name">Baño - Peluquería</div></div>
                                        <div className='content-service'><div className="category-image"><img src={experienciaService} alt="firulife-img" /></div> <div className="category-name">Experiencias Pet friendly</div></div>
                                        <div className='content-service'><div className="category-image"><img src={refugioService} alt="firulife-img" /></div> <div className="category-name">Refugio para mascotas</div></div>
                                        <div className='content-service'><div className="category-image"><img src={masService} alt="firulife-img" /></div> <div className="category-name">Y más</div></div>
                                    </div>

                                </div>
                            </div>
                            <div className='coontent-2-right'>
                            <picture>
                                <source media="(max-width: 900px)" srcSet={content2_1Img} />
                                <source media="(min-width: 901px)" srcSet={content2Img} />
                                <img src={content2Img} alt="Descripción de la imagen" />
                            </picture>
                            </div>
                        </div>
                    </div>
                    <ModalDetailStore
                        dataStore={dataStore}
                        setDataStore={setDataStore}
                        categoryValue={categoryValue}
                        setLoading2={setLoading2}
                        setstoreFavorite={setstoreFavorite}
                        isHome={true}
                    />
                    <Footer/>
                    {
                        loading2 && <Loading />
                    }
                </div>
            }
        </>
	);
}

export default Home;